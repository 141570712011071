import React, { Component } from "react";
import CookieConsent from "react-cookie-consent";
import ShopForm from "./components/Shop";
import logo from "./images/logo_title.png";
import { Button, Tabs, Tab } from "tabler-react";
import { Init } from "./components/Init";
import { About } from "./components/About";
import { Contact } from "./components/Contact";
import { Results } from "./components/Results";
import MetaTags from "react-meta-tags";

import "./App.css";
import "bulma/css/bulma.css";
import "tabler-react/dist/Tabler.css";

class App extends Component {
  openInstragram() {
    window.open("https://www.instagram.com/loteria2debarbera/", "_blank"); //to open new page
  }

  openFacebook() {
    window.open("https://www.facebook.com/loteria2debarbera/", "_blank"); //to open new page
  }

  render() {
    return (
      <div className="App container is-desktop">
        <div>
          <MetaTags>
            <meta name="title" content="Loter&iacute;a 2 de Barber&agrave;" />
            <meta
              name="description"
              content="Administraci&oacute;n de Loter&iacute;a n&ordm; 2 de Barber&agrave; del Vall&egrave;s"
            />
            <meta
              name="keywords"
              content="y si cae aqui, comprar, loteria, comprar loteria, premio, suerte, barbera, gordo, navidad, niño, decimo, quiniela, bonoloto, nacional, primitiva, euromillones, quinigol, hipica, quintople, plus, lototurf, valles, valles occidental, sorteo, premiado, agraciado, pedrea, rico, dinero, barbera, barcelona, cataluña, catalunya, españa, espanya"
            />
            <meta name="language" content="es" />
            <meta
              name="subject"
              content="Administraci&oacute;n de Loter&iacute;a n&ordm; 2 de Barber&agrave; del Vall&egrave;s"
            />
            <meta name="robots" content="All" />
            <meta
              name="copyright"
              content="Administraci&oacute;n de Loter&iacute;a n&ordm; 2 de Barber&agrave; del Vall&egrave;s"
            />
            <meta
              name="abstract"
              content="Administraci&oacute;n de Loter&iacute;a n&ordm; 2 de Barber&agrave; del Vall&egrave;s"
            />
            <meta name="MSSmartTagsPreventParsing" content="true" />
          </MetaTags>
        </div>
        <div>
          <nav
            class="navbar is-fixed-top"
            role="navigation"
            aria-label="main navigation"
          >
            <div class="navbar-brand">
              <a class="navbar-item" href="https://loteria2debarbera.com">
                <img src={logo} alt="logo" />
              </a>
            </div>

            <div id="navbar-social" class="navbar-menu">
              <div class="navbar-end">
                <Button.List>
                  <Button social="facebook" onClick={this.openFacebook}>
                    Facebook
                  </Button>
                  <Button social="instagram" onClick={this.openInstragram}>
                    Instagram
                  </Button>
                </Button.List>
              </div>
            </div>
          </nav>
        </div>
        <div className="has-header">
          <Tabs initialTab="Inicio">
            <Tab title="Inicio" className="navbar-item">
              <Init />
            </Tab>
            <Tab title="Conócenos">
              <About />
            </Tab>
            <Tab title="Resultados">
              <Results />
            </Tab>
            <Tab title="Comprar">
              <ShopForm />
            </Tab>
            <Tab title="Contacto">
              <Contact />
            </Tab>
          </Tabs>
        </div>
        <br></br>
        <br></br>
        <footer class="footer">
          <div class="content has-text-centered">
            <p>
              <strong>Lotería 2 de Barberà</strong>&nbsp;&nbsp; Avda.
              Generalitat, 41-43&nbsp;&nbsp;&nbsp;&nbsp;
              <a href="tel:93 718 73 93">Tel. 93 718 73 93</a>
              &nbsp;&nbsp;&nbsp;&nbsp;08210&nbsp;&nbsp;&nbsp;&nbsp;BARBERÀ DEL
              VALLÈS&nbsp;&nbsp;&nbsp;&nbsp;BARCELONA
              <br></br>
              <i>Built by</i>&nbsp;&nbsp;
              <a href="mailto:sergi.bc@gmail.com">SBC</a>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <a href="https://bulma.io">
                <img
                  src="https://bulma.io/images/made-with-bulma.png"
                  alt="Made with Bulma"
                  width="128"
                  height="24"
                />
              </a>
            </p>
          </div>
        </footer>
        <CookieConsent
          location="bottom"
          buttonText="Entendido"
          cookieName="l2bCookieConsent"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
          hideOnAccept="true"
        >
          Esta web utiliza cookies para mejorar la experiencia de usuario.{" "}
        </CookieConsent>
      </div>
    );
  }
}

export default App;
