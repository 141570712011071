import React, { Component } from "react";
import { Container, GalleryCard, Grid, Text } from "tabler-react";

import "bulma/css/bulma.css";

import map from "../images/map.jpeg";

export class Contact extends Component {
  render() {
    return (
      <div>
        <Container id={"idContact"}>
          {/* <Header.H2>Contacto</Header.H2> */}
          <Text left="true">Avda. Generalitat, 41-43</Text>
          <Text left="true"><a href="tel:93 718 73 93">Tel. 93 718 73 93</a></Text>
          <Text left="true">08210</Text>
          <Text left="true">BARBERÀ DEL VALLÈS</Text>
          <Text left="true">BARCELONA</Text>
          <Text left="true"><a href="mailto:pedidos@loteria2debarbera.com">pedidos@loteria2debarbera.com</a></Text>
          <br />
          <Grid.Row>
            <Grid.Col>
              <GalleryCard.Image
                src={map}
                href="http://maps.google.es/maps?ie=UTF8&q=loteria+barbera&fb=1&split=1&gl=es&ei=zoqwSrG9GOWg4QavoZDtBw&cid=8978970631554391956&li=lmd&ll=41.515438,2.122765&spn=0.012661,0.01929&z=16&iwloc=A"
                target="_blank"
              />
            </Grid.Col>
          </Grid.Row>
        </Container>
      </div>
    );
  }
}
