import React, { Component } from "react";
import { Highlighted } from "./Highlighted";
import { Container, GalleryCard, Grid } from "tabler-react";

import "bulma/css/bulma.css";



export class Init extends Component {
  render() {
    return (
      <div>
        <Highlighted />
        <Container>
          <Grid>
            {/* <Grid.Col>
              <GalleryCard.Image src={prensa} />
            </Grid.Col> */}
            <br></br>
            <Grid.Col>
              <GalleryCard.Image src="https://loteria2debarbera.com/images/portada_00.jpg" />
            </Grid.Col>
            <br></br>
            <Grid.Col>
              <GalleryCard.Image src="https://loteria2debarbera.com/images/portada_0.jpg" />
            </Grid.Col>
            <br></br>
            <Grid.Col>
              <GalleryCard.Image src="https://loteria2debarbera.com/images/portada_1.jpg" />
            </Grid.Col>
          </Grid>
        </Container>
      </div>
    );
  }
}
