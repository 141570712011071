import React, { Component } from "react";

import { Container, GalleryCard, Grid } from "tabler-react";

import "bulma/css/bulma.css";

import bonoloto from "../images/bonoloto.png";
import euro_millones from "../images/euro_millones.png";
import gordo from "../images/gordo.png";
import nacional from "../images/nacional.png";
import lototurf from "../images/lototurf.png";
import primitiva from "../images/primitiva.png";
import quiniela from "../images/quiniela.png";
import quinigol from "../images/quinigol.png";
import quintuple from "../images/quintuple.png";

export class Results extends Component {
  render() {
    return (
      <div>
        <Container>
          <Grid.Row justifyContent="center">
            <Grid.Col width={4}>
              <GalleryCard>
                <GalleryCard.Image
                  src={nacional}
                  href="http://www.loteriasyapuestas.es/es/loteria-nacional"
                  target="_blank"
                />
              </GalleryCard>
            </Grid.Col>
            <Grid.Col width={4}>
              <GalleryCard>
                <GalleryCard.Image
                  src={primitiva}
                  href="http://www.loteriasyapuestas.es/es/la-primitiva"
                  target="_blank"
                />
              </GalleryCard>
            </Grid.Col>
            <Grid.Col width={4}>
              <GalleryCard>
                <GalleryCard.Image
                  src={bonoloto}
                  href="http://www.loteriasyapuestas.es/es/bonoloto"
                  target="_blank"
                />
              </GalleryCard>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row justifyContent="center">
            <Grid.Col width={4}>
              <GalleryCard>
                <GalleryCard.Image
                  src={gordo}
                  href="http://www.loteriasyapuestas.es/es/gordo-primitiva"
                  target="_blank"
                />
              </GalleryCard>
            </Grid.Col>
            <Grid.Col width={4}>
              <GalleryCard>
                <GalleryCard.Image
                  src={euro_millones}
                  href="http://www.loteriasyapuestas.es/es/euromillones"
                  target="_blank"
                />
              </GalleryCard>
            </Grid.Col>
            <Grid.Col width={4}>
              <GalleryCard>
                <GalleryCard.Image
                  src={quiniela}
                  href="http://www.loteriasyapuestas.es/es/la-quiniela"
                  target="_blank"
                />
              </GalleryCard>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row justifyContent="center">
            <Grid.Col width={4}>
              <GalleryCard>
                <GalleryCard.Image
                  src={quinigol}
                  href="http://www.loteriasyapuestas.es/es/el-quinigol"
                  target="_blank"
                />
              </GalleryCard>
            </Grid.Col>
            <Grid.Col width={4}>
              <GalleryCard>
                <GalleryCard.Image
                  src={quintuple}
                  href="http://www.loteriasyapuestas.es/es/quintupleplus"
                  target="_blank"
                />
              </GalleryCard>
            </Grid.Col>
            <Grid.Col width={4}>
              <GalleryCard>
                <GalleryCard.Image
                  src={lototurf}
                  href="http://www.loteriasyapuestas.es/es/lototurf"
                  target="_blank"
                />
              </GalleryCard>
            </Grid.Col>
          </Grid.Row>
        </Container>
      </div>
    );
  }
}
