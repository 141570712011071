import React, { Component } from "react";
import { Button } from "tabler-react";

import "bulma/css/bulma.css";

export class Highlighted extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textos: null,
    };
  }

  componentDidMount() {
    fetch('/texts/texts.json')
      .then((response) => response.json())
      .then((data) => {
        this.setState({ textos: data });
      })
      .catch((error) => console.error('Error loading texts:', error));
  }

  // Mètodes per obrir enllaços externs
  openInstragram() {
    window.open("https://www.instagram.com/loteria2debarbera/", "_blank");
  }

  openFacebook() {
    window.open("https://www.facebook.com/loteria2debarbera/", "_blank");
  }

  render() {
    const { textos } = this.state;

    if (!textos) {
      return <div>Loading...</div>;
    }

    return (
      <div class="tile is-ancestor">
        <div className="tile is-parent is-3">
          <article class="tile is-child notification is-info">
            <p class="title is-6 has-text-left">{textos.premios.title}</p>
            <p class="subtitle is-6  has-text-left">
              {textos.premios.points.map((point, index) => (
                <span key={index}>· {point}<br/></span>
              ))}
            </p>
          </article>
        </div>
        <div className="tile is-parent is-vertical is-3">
          <div className="tile">
            <article class="tile is-child notification is-warning">
              <p class="title is-6 has-text-left">{textos.reservas_0.title}</p>
              <p class="subtitle is-6 has-text-left">
                {textos.reservas_0.points.map((point, index) => (
                <span key={index}>· {point}<br/></span>
              ))}
              </p>
            </article>
          </div>
        </div>
        <div className="tile is-parent is-vertical is-3">
          <div className="tile">
            <article class="tile is-child notification is-success">
              <p class="title is-6 has-text-left">{textos.rrss.title}</p>
              <Button.List>
                <Button social="facebook" onClick={this.openFacebook}>
                  Facebook
                </Button>
                <Button social="instagram" onClick={this.openInstragram}>
                  Instagram
                </Button>
              </Button.List>
            </article>
          </div>
        </div>
        <div className="tile is-parent is-vertical is-3">
          <div className="tile">
            <article class="tile is-child notification is-warning">
              <p class="title is-6 has-text-left">{textos.reservas_1.title}</p>
              <p class="subtitle is-6 has-text-left">
                {textos.reservas_1.content}<a href="tel:{textos.reservas_1.number}">{textos.reservas_1.phone}</a>
              </p>
            </article>
          </div>
        </div>
      </div>
    );
  }
}
