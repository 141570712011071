import React, { Component } from "react";
import { Container, GalleryCard, Grid, Text } from "tabler-react";

import "bulma/css/bulma.css";

import prensa0 from "../images/premsa_nadal_2016.jpg";
import prensa1 from "../images/premsa_gordo_2022_0.jpg";
import prensa2 from "../images/premsa_gordo_2022_1.jpg";
import premios0 from "../images/IMG_20230412_165432_8_2_compress.jpeg";
import premios1 from "../images/IMG_8372_compress.jpeg";
import premios2 from "../images/IMG_20230412_165449_3_2._compress.jpeg";
import premios3 from "../images/cartel_gordo_2022.jpg";
import premios4 from "../images/IMG_20230413_173315_1_2_compress.jpeg";
import puerta from "../images/IMG_8376_compress.jpeg";
import dentro from "../images/IMG_8380_compress.jpeg";

export class About extends Component {
  render() {
    return (
      <div>
        <Container>
          {/* <Header.H2>Sobre Lotería 2 de Barberà</Header.H2> */}
          <Text left="true" justified="true">
            La Administración de Loterías nº 2 abrió sus puertas el día 6 de
            Julio de 1987. Sus principios fueron en la calle Josep Armengol nº3,
            en el casco antiguo del municipio de Barberà del Vallès. Estuvo allí
            ubicada hasta el año 2000, cuando fue trasladada a su actual lugar
            en la Av. Generalitat nº41-43 de dicho municipio.
          </Text>
          <br />
          <Text left="true">
            Somos un negocio familiar, creado por Emilio y ahora gestionado por
            sus hijos Maribel y Alfonso, que cada mañana abren las puertas de su
            negocio, con la ilusión de hacer realidad los sueños de muchos de
            sus clientes y amigos, dándoles importantes premios a lo largo de
            estos años.
          </Text>
          <br />
          <Grid.Row>
            <Grid.Col>
              <GalleryCard.Image src={premios3} />
            </Grid.Col>
          </Grid.Row>
          <br />
          <Grid.Row>
            <Grid.Col>
              <GalleryCard.Image src={prensa1} />
            </Grid.Col>
            <Grid.Col>
              <GalleryCard.Image src={prensa2} />
            </Grid.Col>
          </Grid.Row>
          <br />
          <Grid.Row>
            <Grid.Col>
              <GalleryCard.Image src={prensa0} />
            </Grid.Col>
            <Grid.Col>
              <GalleryCard.Image src={dentro} />
            </Grid.Col>
            <Grid.Col>
              <GalleryCard.Image src={puerta} />
            </Grid.Col>
          </Grid.Row>
          <br />
          <Grid.Row>
            <Grid.Col>
              <GalleryCard.Image src={premios0} />
            </Grid.Col>
            <Grid.Col>
              <GalleryCard.Image src={premios1} />
            </Grid.Col>
          </Grid.Row>
          <br />
          <Grid.Row>
            <Grid.Col>
              <GalleryCard.Image src={premios2} />
            </Grid.Col>
            <Grid.Col>
              <GalleryCard.Image src={premios4} />
            </Grid.Col>
          </Grid.Row>
        </Container>
      </div>
    );
  }
}
