import React from "react";
/* eslint-disable no-undef */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-unused-expressions */
//<script type="text/javascript" src="https://cdn.jsdelivr.net/npm/@emailjs/browser@3/dist/email.min.js"></script>;
import emailjs from '@emailjs/browser';
<script type="text/javascript">
   (function(){

      emailjs.init("aARsitlLU9zVPuJ5A")
   })();
</script>

function sendJS(to, body) {
    //emailjs.init("aARsitlLU9zVPuJ5A")
    console.log("send email to " + to);
    /*var content = "<html>" +
    "<body>" +
    "<h1>NUEVO PEDIDO</h1>" +
    "<p>Ha recibido este email porque ha realizado un pedido de lotería en <a href='https://loteria2debarbera.com' target='_blank' rel='noopener noreferrer'>https://loteria2debarbera.com</a>. Si usted no ha realizado dicho pedido le rogamos que nos disculpe e ignore el contenido del mensaje.</p>" +
    "<p>Por favor, revise los datos del pedido y consulte las observaciones. Si existe algun error o duda no dude en ponerse en contacto con nosotros <a href='https://loteria2debarbera.com' target='_blank' rel='noopener noreferrer'>https://loteria2debarbera.com</a></p>" +
    "<br></br>" +
    "<h3>DATOS DEL CLIENTE</h3>" +
    "<div>Nombre: " +
    body.inputName +
    "</div>" +
    "<div>Primer Apellido: " +
    body.inputSurname1 +
    "</div>" +
    "<div>Segundo Apellido: " +
    body.inputSurname2 +
    "</div>" +
    "<div>Dirección: " +
    body.inputAddress +
    "</div>" +
    "<div>Código Postal: " +
    body.inputPostalCode +
    "</div>" +
    "<div>Población: " +
    body.inputCity +
    "</div>" +
    "<div>Teléfono: " +
    body.inputPhone +
    "</div>" +
    "<div>Email: " +
    body.inputEmail +
    "</div>" +
    "<div>Observaciones: " +
    body.inputComments +
    "</div>" +
    "<br>" +
    "<h3>NÚMEROS A COMPRAR</h3>" +
    "<h4>Terminaciones</h4>" +
    "<div>Décimo XXXX0: " +
    body.inputQuantity0 +
    "</div>" +
    "<div>Décimo XXXX1: " +
    body.inputQuantity1 +
    "</div>" +
    "<div>Décimo XXXX2: " +
    body.inputQuantity2 +
    "</div>" +
    "<div>Décimo XXXX3: " +
    body.inputQuantity3 +
    "</div>" +
    "<div>Décimo XXXX4: " +
    body.inputQuantity4 +
    "</div>" +
    "<div>Décimo XXXX5: " +
    body.inputQuantity5 +
    "</div>" +
    "<div>Décimo XXXX6: " +
    body.inputQuantity6 +
    "</div>" +
    "<div>Décimo XXXX7: " +
    body.inputQuantity7 +
    "</div>" +
    "<div>Décimo XXXX8: " +
    body.inputQuantity8 +
    "</div>" +
    "<div>Décimo XXXX9: " +
    body.inputQuantity9 +
    "</div>" +
    "<h4>Cualquier número</h4>" +
    "<div>Décimo XXXX?: " +
    body.inputQuantityX +
    "</div>" +
    "<h4>Serie loca del 0 al 9</h4>" +
    "<div>10 décimos del 0 al 9: " +
    body.inputSerieLoca +
    "</div>" +
    "<h3>TOTAL</h4>" +
    "<div>Total décimos: " +
    body.count +
    "</div>" +
    "<div>Importe total: " +
    body.total +
    "</div>" +
    "<br>" +
    "<div><a href='https://loteria2debarbera.com/aviso_es.html' target='_blank'>Términos y condiciones aceptadas</a></div>" +
    "<p>" +
    "<strong>Lotería 2 de Barberà</strong>" +
    "<br></br>" +
    "Avda. Generalitat, 41-43" +
    "<br></br>" +
    "<a href='tel:93 718 73 93'>Tel. 93 718 73 93</a>" +
    "<br></br>" +
    "08210&nbsp;&nbsp;BARBERÀ DEL VALLÈS" +
    "<br></br>" +
    "BARCELONA" +
    "</p>" +
    "</body>" +
    "</html>";
*/
    var templateParams = {
        email_to: to,
        name: body.inputName,
        surname1: body.inputSurname1,
        surname2: body.inputSurname2,
        address: body.inputAddress,
        city: body.inputCity,
        region: body.inputRegion,
        postal_code: body.inputPostalCode,
        phone: body.inputPhone,
        email: body.inputEmail,
        comments: body.inputComments,
        ending0: body.inputQuantity0,
        ending1: body.inputQuantity1,
        ending2: body.inputQuantity2,
        ending3: body.inputQuantity3,
        ending4: body.inputQuantity4,
        ending5: body.inputQuantity5,
        ending6: body.inputQuantity6,
        ending7: body.inputQuantity7,
        ending8: body.inputQuantity8,
        ending9: body.inputQuantity9,
        any_number: body.inputQuantityX,
        crazy: body.inputSerieLoca,
        total_numbers: body.count,
        total_amount: body.total
    };

    return emailjs.send('service_7znykf3', 'template_fyolpcj', templateParams)
    .then(function(response) {
       console.log('SUCCESS!', response.status, response.text);
    }, function(error) {
       console.log('FAILED...', error);
    });
}

export default sendJS;