import React, { Component } from "react";
import ReactDOM from "react-dom";
//import email from "./Email";
import emailJS from "./EmailJS";
import {
  Alert,
  Container,
  Form,
  Grid,
  Header,
  Button,
  Text,
} from "tabler-react";

export default class ShopForm extends Component {
  constructor() {
    super();
    this.state = {
      inputQuantity0: "",
      inputQuantity1: "",
      inputQuantity2: "",
      inputQuantity3: "",
      inputQuantity4: "",
      inputQuantity5: "",
      inputQuantity6: "",
      inputQuantity7: "",
      inputQuantity8: "",
      inputQuantity9: "",
      inputQuantityX: "",
      inputSerieLoca: "",
      inputName: "",
      inputSurname1: "",
      inputSurname2: "",
      inputAddress: "",
      inputPostalCode: "",
      inputCity: "",
      inputRegion: "",
      inputPhone: "",
      inputEmail: "",
      inputComments: "",
      inputTerms: false,
      count: 0,
      total: 0,
      calculated: false,
    };
  }

  openLegal() {
    window.open("https://loteria2debarbera.com/aviso_es.html", "_blank"); //to open new page
  }

  validateEmail(email) {
    // eslint-disable-next-line
    var regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(String(email).toLowerCase());
  }

  handleSubmit = (e) => {
    e.preventDefault();
    console.log(this.state);
    console.log(this.state.inputEmail);
    console.log("check: " + this.state.inputTerms);
    console.log("submit-calculated: " + this.state.calculated);
    if (this.state.calculated) {
      if (this.state.inputTerms) {
        if (this.state.count < 5) {
          this.showAlert();
          ReactDOM.render(
            <Alert type="danger" icon="alert-triangle">
              El pedido mínimo es de 5 décimos
            </Alert>,
            document.getElementById("alert-container")
          );
        } else {
          var sent = emailJS(this.state.inputEmail, this.state);
          sent.then(
            (message) => {
              console.log("guay!!!");
              alert(
                "GENIAL! El pedido se ha enviado con éxito. En breve recibirá un correo en la dirección indicada. Por favor revíselo y no dude en contactarnos si tiene algún comentario. ¡Muchas gracias y suerte!"
              );
              window.location.reload(false);
            },
            function (error) {
              console.log("fuccck!!");
              alert(
                "Ha habido un problema. Reinténtelo de nuevo o póngase en contacto con nosotros. Error:" +
                  error
              );
            }
          );
        }
      } else {
        this.showAlert();
        ReactDOM.render(
          <Alert type="danger" icon="alert-triangle">
            Debe aceptar las condiciones
          </Alert>,
          document.getElementById("alert-container")
        );
      }
    } else {
      this.showAlert();
      ReactDOM.render(
        <Alert type="danger" icon="alert-triangle">
          Pulse en 'Calcular' para revisar los totales.
        </Alert>,
        document.getElementById("alert-container")
      );
    }
  };

  handleTermsChecked = (e) => {
    console.log("handleChange");
    console.log("check:" + e.target.checked);
    this.setState({ inputTerms: e.target.checked });
    if (e.target.checked) this.hideAlert();
  };

  calculate() {
    const value = 20;
    var count = 0;
    var count10 = 0;
    if (this.state.inputQuantity0.length > 0) {
      count += parseInt(this.state.inputQuantity0);
    }
    if (this.state.inputQuantity1.length > 0) {
      count += parseInt(this.state.inputQuantity1);
    }
    if (this.state.inputQuantity2.length > 0) {
      count += parseInt(this.state.inputQuantity2);
    }
    if (this.state.inputQuantity3.length > 0) {
      count += parseInt(this.state.inputQuantity3);
    }
    if (this.state.inputQuantity4.length > 0) {
      count += parseInt(this.state.inputQuantity4);
    }
    if (this.state.inputQuantity5.length > 0) {
      count += parseInt(this.state.inputQuantity5);
    }
    if (this.state.inputQuantity6.length > 0) {
      count += parseInt(this.state.inputQuantity6);
    }
    if (this.state.inputQuantity7.length > 0) {
      count += parseInt(this.state.inputQuantity7);
    }
    if (this.state.inputQuantity8.length > 0) {
      count += parseInt(this.state.inputQuantity8);
    }
    if (this.state.inputQuantity9.length > 0) {
      count += parseInt(this.state.inputQuantity9);
    }
    if (this.state.inputQuantityX.length > 0) {
      count += parseInt(this.state.inputQuantityX);
    }
    if (this.state.inputSerieLoca.length > 0) {
      count10 += parseInt(this.state.inputSerieLoca);
    }
    var totalCount = count + 10 * count10;
    console.log("cantidad decimos: " + totalCount);
    this.setState({ count: totalCount });
    console.log("total decimos: " + totalCount * value);
    this.setState({ total: totalCount * value });
    this.setState({ calculated: true });
    console.log("calculate-calculated: " + this.state.calculated);
    this.hideAlert();
  }

  hideAlert() {
    console.log(
      "hide: " + document.getElementById("alert-container").style.display
    );
    document.getElementById("alert-container").style.display = "none";
  }

  showAlert() {
    console.log(
      "hide: " + document.getElementById("alert-container").style.display
    );
    document.getElementById("alert-container").style.display = "";
  }

  handleQuantities(evt, inputElement) {
    console.log("handleQuantities");
    console.log(evt.target.value + " - " + inputElement);
    const value = evt.target.validity.valid ? evt.target.value : inputElement;

    this.setState({ inputElement: value });
  }

  render() {
    const REG_EXP_NUMBER = /^[0-9]*$/;
    const VALUE = 20;
    return (
      <div>
        <Container>
          <Header.H3>Instrucciones</Header.H3>
          <Text className="has-text-justified">
            Escoja la terminación que más le guste, e indique en la casilla
            correspondiente el número de décimos que quiera adquirir. La compra
            total mínima es de 5 décimos. Cuando haya seleccionado sus décimos,
            rellene todos los datos del formulario para enviar el pedido.
            Recibirá un email con los datos del pedido. Revíselo y si encuentra
            algun error póngase en contacto con nosotros.
          </Text>
          <Text className="has-text-justified">
            En el plazo de dos días laborables recibirá un email de confirmación
            del pedido, una vez hayamos comprobado la disponibilidad. En el
            mismo, se le informará del número de cuenta y cuantía, incluyendo
            los gastos de envío, para la transferencia bancaria. Es
            imprescindible realizar el ingreso con un mínimo de 72 horas antes
            del sorteo, y siempre después de que el pedido haya sido confirmado.
          </Text>
          <Text className="has-text-justified">
            Todos nuestros pedidos son enviados por SEUR-24, asegurados a "todo
            riesgo" cubriendo 100% de su valor. En 24 horas lo tendrá en sus
            manos.
          </Text>
          <Text className="has-text-danger has-text-justified has-text-weight-bold">
            MUY IMPORTANTE: A la hora de hacer el ingreso, indique como
            ordenante, el nombre facilitado en el pedido.
          </Text>
          <p>
            <br></br>
            <Button color="warning" onClick={this.openLegal}>
              AVISO LEGAL y PROTECCIÓN DE DATOS
            </Button>
          </p>
          <br></br>
          <Form onSubmit={this.handleSubmit}>
            <div class="columns">
              <div class="column">
                <div>
                  <Header.H3>Números a comprar</Header.H3>
                  <Form.FieldSet>
                    <Header.H4>Terminaciones</Header.H4>
                    <Form.Group isRequired label="Décimo XXXX0 - Cantidad">
                      <Form.Input
                        id="quantity_0"
                        name="quantity_0"
                        onChange={(e) => {
                          if (REG_EXP_NUMBER.test(String(e.target.value))) {
                            this.setState({ inputQuantity0: e.target.value });
                            this.setState({ calculated: false });
                          }
                        }}
                        ref={(inputElement) =>
                          (this.inputQuantity0 = inputElement)
                        }
                        value={this.state.inputQuantity0}
                        placeholder="0"
                        autoComplete="off"
                        type="text"
                      />
                    </Form.Group>
                    <Form.Group isRequired label="Décimo XXXX1 - Cantidad">
                      <Form.Input
                        id="quantity_1"
                        name="quantity_1"
                        onChange={(e) => {
                          if (REG_EXP_NUMBER.test(String(e.target.value))) {
                            this.setState({ inputQuantity1: e.target.value });
                            this.setState({ calculated: false });
                          }
                        }}
                        ref={(inputElement) =>
                          (this.inputQuantity1 = inputElement)
                        }
                        value={this.state.inputQuantity1}
                        placeholder="0"
                        autoComplete="off"
                        type="text"
                      />
                    </Form.Group>
                    <Form.Group isRequired label="Décimo XXXX2 - Cantidad">
                      <Form.Input
                        id="quantity_2"
                        name="quantity_2"
                        onChange={(e) => {
                          if (REG_EXP_NUMBER.test(String(e.target.value))) {
                            this.setState({ inputQuantity2: e.target.value });
                            this.setState({ calculated: false });
                          }
                        }}
                        ref={(inputElement) =>
                          (this.inputQuantity2 = inputElement)
                        }
                        value={this.state.inputQuantity2}
                        placeholder="0"
                        autoComplete="off"
                        type="text"
                      />
                    </Form.Group>
                    <Form.Group isRequired label="Décimo XXXX3 - Cantidad">
                      <Form.Input
                        id="quantity_3"
                        name="quantity_3"
                        onChange={(e) => {
                          if (REG_EXP_NUMBER.test(String(e.target.value))) {
                            this.setState({ inputQuantity3: e.target.value });
                            this.setState({ calculated: false });
                          }
                        }}
                        ref={(inputElement) =>
                          (this.inputQuantity3 = inputElement)
                        }
                        value={this.state.inputQuantity3}
                        placeholder="0"
                        autoComplete="off"
                        type="text"
                      />
                    </Form.Group>
                    <Form.Group isRequired label="Décimo XXXX4 - Cantidad">
                      <Form.Input
                        id="quantity_4"
                        name="quantity_4"
                        onChange={(e) => {
                          if (REG_EXP_NUMBER.test(String(e.target.value))) {
                            this.setState({ inputQuantity4: e.target.value });
                            this.setState({ calculated: false });
                          }
                        }}
                        ref={(inputElement) =>
                          (this.inputQuantity4 = inputElement)
                        }
                        value={this.state.inputQuantity4}
                        placeholder="0"
                        autoComplete="off"
                        type="text"
                      />
                    </Form.Group>
                    <Form.Group isRequired label="Décimo XXXX5 - Cantidad">
                      <Form.Input
                        id="quantity_5"
                        name="quantity_5"
                        onChange={(e) => {
                          if (REG_EXP_NUMBER.test(String(e.target.value))) {
                            this.setState({ inputQuantity5: e.target.value });
                            this.setState({ calculated: false });
                          }
                        }}
                        ref={(inputElement) =>
                          (this.inputQuantity5 = inputElement)
                        }
                        value={this.state.inputQuantity5}
                        placeholder="0"
                        autoComplete="off"
                        type="text"
                      />
                    </Form.Group>
                    <Form.Group isRequired label="Décimo XXXX6 - Cantidad">
                      <Form.Input
                        id="quantity_6"
                        name="quantity_6"
                        onChange={(e) => {
                          if (REG_EXP_NUMBER.test(String(e.target.value))) {
                            this.setState({ inputQuantity6: e.target.value });
                            this.setState({ calculated: false });
                          }
                        }}
                        ref={(inputElement) =>
                          (this.inputQuantity6 = inputElement)
                        }
                        value={this.state.inputQuantity6}
                        placeholder="0"
                        autoComplete="off"
                        type="text"
                      />
                    </Form.Group>
                    <Form.Group isRequired label="Décimo XXXX7 - Cantidad">
                      <Form.Input
                        id="quantity_7"
                        name="quantity_7"
                        onChange={(e) => {
                          if (REG_EXP_NUMBER.test(String(e.target.value))) {
                            this.setState({ inputQuantity7: e.target.value });
                            this.setState({ calculated: false });
                          }
                        }}
                        ref={(inputElement) =>
                          (this.inputQuantity7 = inputElement)
                        }
                        value={this.state.inputQuantity7}
                        placeholder="0"
                        autoComplete="off"
                        type="text"
                      />
                    </Form.Group>
                    <Form.Group isRequired label="Décimo XXXX8 - Cantidad">
                      <Form.Input
                        id="quantity_8"
                        name="quantity_8"
                        onChange={(e) => {
                          if (REG_EXP_NUMBER.test(String(e.target.value))) {
                            this.setState({ inputQuantity8: e.target.value });
                            this.setState({ calculated: false });
                          }
                        }}
                        ref={(inputElement) =>
                          (this.inputQuantity8 = inputElement)
                        }
                        value={this.state.inputQuantity8}
                        placeholder="0"
                        autoComplete="off"
                        type="text"
                      />
                    </Form.Group>
                    <Form.Group isRequired label="Décimo XXXX9 - Cantidad">
                      <Form.Input
                        id="quantity_9"
                        name="quantity_9"
                        onChange={(e) => {
                          if (REG_EXP_NUMBER.test(String(e.target.value))) {
                            this.setState({ inputQuantity9: e.target.value });
                            this.setState({ calculated: false });
                          }
                        }}
                        ref={(inputElement) =>
                          (this.inputQuantity9 = inputElement)
                        }
                        value={this.state.inputQuantity9}
                        placeholder="0"
                        autoComplete="off"
                        type="text"
                      />
                    </Form.Group>
                    <Header.H4>Cualquier número</Header.H4>
                    <Form.Group isRequired label="Décimo XXXX? - Cantidad">
                      <Form.Input
                        id="quantity_x"
                        name="quantity_x"
                        onChange={(e) => {
                          if (REG_EXP_NUMBER.test(String(e.target.value))) {
                            this.setState({ inputQuantityX: e.target.value });
                            this.setState({ calculated: false });
                          }
                        }}
                        ref={(inputElement) =>
                          (this.inputQuantityX = inputElement)
                        }
                        value={this.state.inputQuantityX}
                        placeholder="0"
                        autoComplete="off"
                        type="text"
                      />
                    </Form.Group>
                    <Header.H4>Serie loca del 0 al 9</Header.H4>
                    <Form.Group isRequired label="10 décimos del 0 al 9">
                      <Form.Input
                        id="serie_loca"
                        name="serie_loca"
                        onChange={(e) => {
                          if (REG_EXP_NUMBER.test(String(e.target.value))) {
                            this.setState({ inputSerieLoca: e.target.value });
                            this.setState({ calculated: false });
                          }
                        }}
                        ref={(inputElement) =>
                          (this.inputSerieLoca = inputElement)
                        }
                        value={this.state.inputSerieLoca}
                        placeholder="0"
                        autoComplete="off"
                        type="text"
                      />
                    </Form.Group>
                    <Button
                      color="secondary"
                      type="button"
                      onClick={(e) => this.calculate()}
                    >
                      Calcular
                    </Button>
                  </Form.FieldSet>
                </div>
              </div>
              <div class="column">
                <div>
                  <Header.H3>Sus datos</Header.H3>
                  <Form.FieldSet>
                    <Form.Group isRequired label="Nombre">
                      <Form.Input
                        id="name"
                        name="userName"
                        onChange={(e) =>
                          this.setState({ inputName: e.target.value })
                        }
                        ref={(inputElement) => (this.inputName = inputElement)}
                        value={this.state.inputName}
                        placeholder="Introduce el nombre"
                        autoComplete="on"
                        type="text"
                        required
                      />
                    </Form.Group>
                    <Form.Group isRequired label="Primer Apellido">
                      <Form.Input
                        id="surname1"
                        name="userSurname1"
                        onChange={(e) =>
                          this.setState({ inputSurname1: e.target.value })
                        }
                        ref={(inputElement) =>
                          (this.inputSurname1 = inputElement)
                        }
                        value={this.state.inputSurname1}
                        placeholder="Introduce primer apellido"
                        autoComplete="on"
                        type="text"
                        required
                      />
                    </Form.Group>
                    <Form.Group label="Segundo Apellido">
                      <Form.Input
                        id="surname2"
                        name="userSurname2"
                        onChange={(e) =>
                          this.setState({ inputSurname2: e.target.value })
                        }
                        placeholder="Introduce el segundo apellido"
                        ref={(inputElement) =>
                          (this.inputSurname2 = inputElement)
                        }
                        value={this.state.inputSurname2}
                        autoComplete="on"
                        type="text"
                      />
                    </Form.Group>
                    <Form.Group isRequired label="Dirección">
                      <Form.Input
                        id="address"
                        name="userAddress"
                        onChange={(e) =>
                          this.setState({ inputAddress: e.target.value })
                        }
                        placeholder="Introduce la dirección"
                        ref={(inputElement) =>
                          (this.inputAddress = inputElement)
                        }
                        value={this.state.inputAddress}
                        autoComplete="on"
                        type="text"
                      />
                    </Form.Group>
                    <Form.Group isRequired label="Código postal">
                      <Form.Input
                        id="postalCode"
                        name="userPostalCode"
                        onChange={(e) => {
                          if (REG_EXP_NUMBER.test(String(e.target.value))) {
                            this.setState({ inputPostalCode: e.target.value });
                          }
                        }}
                        placeholder="Introduce el código postal"
                        ref={(inputElement) =>
                          (this.inputPostalCode = inputElement)
                        }
                        value={this.state.inputPostalCode}
                        autoComplete="on"
                        type="text"
                        invalid={this.state.inputPostalCode.length !== 5}
                        feedback="Código postal incorrecto"
                        required
                      />
                    </Form.Group>
                    <Form.Group isRequired label="Población">
                      <Form.Input
                        id="city"
                        name="userCity"
                        onChange={(e) =>
                          this.setState({ inputCity: e.target.value })
                        }
                        placeholder="Introduce la población"
                        ref={(inputElement) => (this.inputCity = inputElement)}
                        value={this.state.inputCity}
                        autoComplete="on"
                        type="text"
                        required
                      />
                    </Form.Group>
                    <Form.Group isRequired label="Provincia">
                      <Form.Input
                        id="region"
                        name="userRegion"
                        onChange={(e) =>
                          this.setState({ inputRegion: e.target.value })
                        }
                        placeholder="Introduce la provincia"
                        ref={(inputElement) =>
                          (this.inputRegion = inputElement)
                        }
                        value={this.state.inputRegion}
                        autoComplete="on"
                        type="text"
                        required
                      />
                    </Form.Group>
                    <Form.Group label="Teléfono">
                      <Form.Input
                        id="phone"
                        name="userPhone"
                        onChange={(e) =>
                          this.setState({ inputPhone: e.target.value })
                        }
                        placeholder="Introduce el teléfono"
                        ref={(inputElement) => (this.inputPhone = inputElement)}
                        value={this.state.inputPhone}
                        autoComplete="on"
                        type="number"
                      />
                    </Form.Group>
                    <Form.Group isRequired label="Email">
                      <Form.Input
                        id="email"
                        name="userEmail"
                        onChange={(e) => {
                          this.setState({ inputEmail: e.target.value });
                        }}
                        invalid={!this.validateEmail(this.state.inputEmail)}
                        placeholder="Introduce el email"
                        ref={(inputElement) => (this.inputEmail = inputElement)}
                        value={this.state.inputEmail}
                        autoComplete="on"
                        type="email"
                        feedback="Email incorrecto"
                        required
                      />
                    </Form.Group>
                    <Form.Group label="Observaciones">
                      <Form.Textarea
                        id="comments"
                        name="userComments"
                        onChange={(e) =>
                          this.setState({ inputComments: e.target.value })
                        }
                        placeholder="Introduce las observaciones"
                        ref={(inputElement) =>
                          (this.inputComments = inputElement)
                        }
                        value={this.state.inputComments}
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Form.FieldSet>
                  <Form.FieldSet>
                    <Text class="has-text-weight-bold">
                      Cantidad de décimos: {this.state.count}{" "}
                    </Text>
                    <Text class="has-text-weight-bold">
                      Importe total: {this.state.count * VALUE} €
                    </Text>
                  </Form.FieldSet>
                </div>
              </div>
            </div>
            <Grid.Row justifyContent="center">
              <Form.Checkbox
                label="He leído y acepto las"
                isInline
                checked={this.state.inputTerms}
                onChange={this.handleTermsChecked}
              />
              <Text align="left">
                <a href="https://loteria2debarbera.com/aviso_es.html" target="_blank">
                  condiciones
                </a>
              </Text>
            </Grid.Row>
            <div id="alert-container"></div>
            <Button color="primary" type="submit">
              Enviar
            </Button>
          </Form>
        </Container>
      </div>
    );
  }
}
